export const urlvideo="https://api.cobra-education.com/"
export async function getVideoFrameUrl(videoUrl, time = 1) {
    // Create a video element
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous'; // Enable CORS for cross-origin video URLs
    video.src = videoUrl;

    // Return a promise that resolves with the image URL
    return new Promise((resolve, reject) => {
        // Load metadata to get video duration, etc.
        video.addEventListener('loadedmetadata', () => {
            // Set the time to capture the frame
            video.currentTime = time;
        });

        // Wait until the frame at the specified time is available
        video.addEventListener('seeked', () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the video frame to the canvas
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Convert canvas to data URL and return it directly
            const imgUrl = canvas.toDataURL('image/png');
            resolve(imgUrl); // Return the image URL directly
        });

        // Handle video load errors
        video.addEventListener('error', (e) => {
            reject(e);
        });
    });
}
