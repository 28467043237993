import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
} from '@chakra-ui/react';
import axios from 'axios'; // Assuming you're using axios for API calls
import { addChapter } from '../services/courseService';

function NewChapterToCourse({ isOpen, onClose, courseId,fetchCourseData }) {
  const [newChapterName, setNewChapterName] = useState('');
  const [numberOfParts, setNumberOfParts] = useState(1);
  const [partNames, setPartNames] = useState(['']);
  const [loading, setLoading] = useState(false);

  const handlePartCountChange = (value) => {
    setNumberOfParts(value);
    const updatedPartNames = Array(value).fill('');
    setPartNames(updatedPartNames);
  };

  const handlePartNameChange = (index, value) => {
    const updatedPartNames = [...partNames];
    updatedPartNames[index] = value;
    setPartNames(updatedPartNames);
  };

  const handleSaveChapter = async () => {
    const newChapter = {
      nom: newChapterName,
      parties: partNames.filter((partName) => partName.trim() !== '').map(el=>({name:el,videos:[],attachments:[]})),
    };

    try {
      setLoading(true);
      // Replace with your API endpoint
      const response = await addChapter(courseId,newChapter)
      
      console.log('Chapter added:', response.data);
      // You can add any success notification or action here
      fetchCourseData()
      onClose(); // Close the modal after success
    } catch (error) {
      console.error('Error adding chapter:', error);
      // You can add error handling or notifications here
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Chapter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Chapter name"
            value={newChapterName}
            onChange={(e) => setNewChapterName(e.target.value)}
          />
          <Box mt={4}>
            <Text mb={2}>Number of Parts: {numberOfParts}</Text>
            <Slider
              defaultValue={1}
              min={1}
              max={20}
              step={1}
              value={numberOfParts}
              onChange={handlePartCountChange}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
          <Box mt={4}>
            {partNames.map((partName, index) => (
              <Input
                key={index}
                placeholder={`Part name ${index + 1}`}
                value={partName}
                onChange={(e) => handlePartNameChange(index, e.target.value)}
                mt={2}
              />
            ))}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveChapter} isLoading={loading}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewChapterToCourse;
