import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = (hoverState) => setIsHovered(hoverState);

  return (
    <SidebarContext.Provider value={{ isHovered, toggleHover }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
