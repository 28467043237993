import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Spinner, Text } from '@chakra-ui/react';
import axiosInstance from './../services/axiosInstance';
import RegisterUsersButton from '../pages/js';

const VideoPlayer = ({ idvdocipher }) => {
  const [otp, setOtp] = useState('');
  const [playbackInfo, setPlaybackInfo] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOtpAndPlaybackInfo = async () => {
      try {
        // Fetch OTP and playback info from your server
        const response = await axiosInstance.get(`/vdocipher/${idvdocipher}`);
        const { otp, playbackInfo } = response.data;

        // Store them in state
        setOtp(otp);
        setPlaybackInfo(playbackInfo);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching OTP and playback info:', error);
        setError("Failed to load video. Please try again later.");
        setLoading(false);
      }
    };

    fetchOtpAndPlaybackInfo();
  }, [idvdocipher]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="360px">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="360px">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box width="100%" height={450} mx="auto" >
      {otp && playbackInfo && (
        <iframe
        border={"2px black solid"}
          width="100%"
          height="100%"
          src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`}
          allow="encrypted-media"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      )}
     
    </Box>
  );
};

export default VideoPlayer;
