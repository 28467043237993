import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import Sidebar2 from '../shared/Sidebar2';
import CalendarAdmin from '../shared/CalendarAdmin';
import { SidebarProvider, useSidebar } from '../utils/SidebarContext';
import axiosInstance from './../services/axiosInstance';

const EventsContent = () => {
  const { isHovered } = useSidebar();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [events, setEvents] = useState([]);
  const [eventName, setEventName] = useState('');
  const [isLive, setIsLive] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [image, setImage] = useState(null);
  const [professor, setProfessor] = useState(''); // Placeholder, adjust according to your auth system
  
  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get('/events');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDateClick = (date) => {
    const selectedDate = new Date(date.start);

    if (!isNaN(selectedDate.getTime())) {
      const endDateWithAddedDay = new Date(selectedDate);
      endDateWithAddedDay.setDate(endDateWithAddedDay.getDate() + 1);

      setStartDate(selectedDate);
      setEndDate(endDateWithAddedDay);
      onOpen();
    } else {
      console.error('Invalid date clicked');
    }
  };

  const handleCreateEvent = async () => {
    if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date values');
      return;
    }

    const finalEventName = isLive ? `LIVE: ${eventName}` : eventName;

    try {
      const newEvent = {
        title: finalEventName,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        imageUrl: image,
        professor: professor, 
      };

      const formData = new FormData();
      formData.append('title', newEvent.title);
      formData.append('start', newEvent.start);
      formData.append('end', newEvent.end);
      formData.append('imageUrl', newEvent.imageUrl);
      formData.append('professor', newEvent.professor);

      const response = await axiosInstance.post('/events', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setEvents([...events, response.data]);

      setEventName('');
      setIsLive(false);
      setImage(null);
      setStartDate(null);
      setEndDate(null);
      onClose();
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Sidebar2 />
      <Box
        flex="1"
        ml={{ base: '0', md: '250px' }}
        p={6}
        bg="#F7FAFC"
        transition="margin-left 0.3s ease"
        minHeight="100vh"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading
            as="h1"
            size="lg"
            color="#032D7F"
            mb={{ base: 4, md: 0 }}
          >
            Événements
          </Heading>
        </Flex>

        <CalendarAdmin
          fetchEvents={fetchEvents}
          events={events}
          onDateClick={handleDateClick}
        />

        {/* Event Creation Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Créer un Événement</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>Nom de l'Événement</FormLabel>
                <Input
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </FormControl>
              <FormControl mb={4}>
                <Checkbox
                  isChecked={isLive}
                  onChange={(e) => setIsLive(e.target.checked)}
                >
                  LIVE
                </Checkbox>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Date de Début</FormLabel>
                <Input
                  type="datetime-local"
                  value={startDate ? startDate.toISOString().slice(0, 16) : ''}
                  onChange={(e) => setStartDate(new Date(e.target.value))}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Date de Fin</FormLabel>
                <Input
                  type="datetime-local"
                  value={endDate ? endDate.toISOString().slice(0, 16) : ''}
                  onChange={(e) => setEndDate(new Date(e.target.value))}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </FormControl>
              <Button
                colorScheme="teal"
                onClick={handleCreateEvent}
              >
                Créer l'Événement
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

const AdminEvents = () => {
  return (
    <SidebarProvider>
      <EventsContent />
    </SidebarProvider>
  );
};

export default AdminEvents;
