import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useDropzone } from 'react-dropzone';
import axiosInstance from '../services/axiosInstance';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    VStack,
    HStack,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Icon,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useDisclosure,
    Badge,
    SimpleGrid,
    useColorModeValue,
    Spinner,
    useToast
} from '@chakra-ui/react';
import { FaTrash, FaVideo, FaPaperclip, FaPuzzlePiece, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast, ToastContainer } from 'react-toastify';
import { useSidebar } from '../utils/SidebarContext';
import Sidebar2 from '../shared/Sidebar2';
import { deleteVideo, fetchCourseById, toggleChaptire } from '../services/courseService';
import { useParams } from 'react-router-dom';
import VideoPlayer from '../shared/videoplayer';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { FiLock, FiUnlock } from "react-icons/fi";

import 'react-toastify/dist/ReactToastify.css';
import NewChapterToCourse from '../shared/newchapitreCreation';
import { urlvideo } from '../services/urls';
import AddPartieForm from '../shared/AddPartieForm';
import VideoCourseManger from '../shared/VideoCourseManger';
const CourseDetailsContent = () => {
    const { isHovered } = useSidebar() || {};
    const { id } = useParams();

    const [courseData, setCourseData] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [openChapterIndex, setOpenChapterIndex] = useState(0);
    const [openPartieIndex, setOpenPartieIndex] = useState(null);
    const [videoUploadModalOpen, setVideoUploadModalOpen] = useState(false);
    const [selectedChapterIndex, setSelectedChapterIndex] = useState(null);
    const [selectedPartieIndex, setSelectedPartieIndex] = useState(null);
    const [ChaterID, setChaterID] = useState(null);
    const [isChapterModalOpen, setIsChapterModalOpen] = useState(false);
    const [isAddNewPartieOpen, setisAddNewPartieOpen] = useState(false);
    const [newChapter, setNewChapter] = useState({ name: '', parties: [] });
    const [newPartieName, setNewPartieName] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [newChapterName, setNewChapterName] = useState('');
    const [AttchmentName, setAttchmentName] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
    const [newVideo, setNewVideo] = useState({
        name: '',
        description: '',
        duree: '',
        thumbnail: null,
        file: null,
    });
    const [uploadProgress, setUploadProgress] = useState(0);

    const {
        getRootProps: getAttachmentRootProps,
        getInputProps: getAttachmentInputProps,
    } = useDropzone({
        onDrop: (acceptedFiles) => {
            setAttachments(acceptedFiles[0]);
        },
    });

    const closeChapterModal = () => {
        setIsChapterModalOpen(false);
    };
    const accordionHeaderBg = useColorModeValue('gray.100', 'gray.700');
    const accordionBodyBg = useColorModeValue('white', 'gray.800');
    const {
        getRootProps: getVideoRootProps,
        getInputProps: getVideoInputProps,
    } = useDropzone({
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            setNewVideo((prevState) => ({
                ...prevState,
                file,
                thumbnail: URL.createObjectURL(file),
            }));

            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);
            videoElement.onloadedmetadata = () => {
                setNewVideo((prevState) => ({
                    ...prevState,
                    duree: `${Math.floor(videoElement.duration / 60)}:${Math.floor(videoElement.duration % 60)}`,
                }));
            };
        },
    });

    const fetchCourseData = async () => {
        try {
            const response = await fetchCourseById(id);
            setCourseData(response.data);
            if (response.data.chapitres?.length > 0) {
                const firstChapter = response.data.chapitres[0];
                if (firstChapter.parties?.length > 0 && firstChapter.parties[0].videos?.length > 0) {
                    setSelectedVideo(firstChapter.parties[0].videos[0]);
                }
            }
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };

    useEffect(() => {
        fetchCourseData();
    }, [id]);

    const handleVideoSelect = (video) => setSelectedVideo(video);

    const toggleChapter = (index) => setOpenChapterIndex(openChapterIndex === index ? null : index);
    const togglePartie = (index) => setOpenPartieIndex(openPartieIndex === index ? null : index);

    const handleDeleteVideo = async (chapterIndex, partieIndex, videoId) => {
        try {
            await deleteVideo(id, chapterIndex, partieIndex, videoId);
            fetchCourseData();
            setSelectedVideo(null);
            toast({
                title: "Vidéo supprimée ",
                description: "Vidéo supprimée avec succès",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            console.error('Error deleting video:', error);
            toast({
                title: "Erreur ",
                description: "Erreur lors de la suppression de la vidéo",
                status: "error",
                duration: 3000,
                isClosable: true,
            });


        }
    };

    const openVideoUploadModal = (chapterIndex, partieIndex) => {
        setSelectedChapterIndex(chapterIndex);
        setSelectedPartieIndex(partieIndex);
        setVideoUploadModalOpen(true);
    };

    const handleCloseVideoUploadModal = () => {
        setVideoUploadModalOpen(false);
        setNewVideo({
            name: '',
            description: '',
            duree: '',
            thumbnail: null,
            file: null,
        });
        setUploadProgress(0);
    };

    const handleAddVideo = async () => {
        if (selectedChapterIndex !== null && selectedPartieIndex !== null && courseData && newVideo.file) {
            try {
                const formData = new FormData();
                formData.append('video', newVideo.file);
                formData.append('nomvideo', newVideo.name);
                formData.append('description', newVideo.description);
                formData.append('duree', newVideo.duree);
                formData.append('thumbnail', newVideo.thumbnail);
                formData.append('chapitreIndex', selectedChapterIndex);
                formData.append('partieIndex', selectedPartieIndex);

                await axiosInstance.post(`/courses/course/${id}/videos`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                });

                fetchCourseData();


                toast({
                    title: "Vidéo Ajouté. ",
                    description: "Vidéo téléchargée avec succès",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                handleCloseVideoUploadModal();
            } catch (error) {
                console.error('Error uploading video:', error);
                toast({
                    title: "Erreur ",
                    description: "Erreur lors du téléchargement de la vidéo",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });

            }
        }
    };

    const handleAddChapter = async () => {
        if (courseData && newChapter.name) {
            try {
                await axiosInstance.post(`/courses/${id}/chapters`, newChapter);
                fetchCourseData();
                toast({
                    title: "Chapitre ajouté ",
                    description: "Chapitre ajouté avec succès",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
          
                closeChapterModal();
            } catch (error) {
                console.error('Error adding chapter:', error);
                toast({
                    title: "Erreur",
                    description: "Erreur lors de l\'ajout du chapitre",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
      
            }
        }
    };

    const handleDeletePartie = async (chapterIndex, partieIndex) => {
        try {
            const courseId = courseData._id;
            const chapitreId = courseData.chapitres[chapterIndex]._id;
            const partieId = courseData.chapitres[chapterIndex].parties[partieIndex]._id;

            // Send delete request to the server
            await axiosInstance.delete(`courses/courses/${courseId}/chapters/${chapitreId}/parties/${partieId}`);

            // Update the UI by removing the deleted partie
            const updatedCourseData = { ...courseData };
            updatedCourseData.chapitres[chapterIndex].parties.splice(partieIndex, 1);
            setCourseData(updatedCourseData);

            console.log('Partie deleted successfully.');
        } catch (error) {
            console.error('Error deleting partie:', error);
        }
    };


    const saveAttachmentsToPartie = async (chapterIndex, partieIndex, attachment) => {
        try {
            // Ensure that an attachment is provided
            if (!attachment) {
                console.error('Error: No attachment provided.');
                return;
            }

            const formData = new FormData();
            formData.append('file', attachment[0]); // Add the file to the form data
            formData.append('nom', AttchmentName); // Add the attachment name

            // Ensure courseData is available
            const course = courseData;
            if (!course) {
                console.error('Error: Course not found.');
                return;
            }

            // Retrieve chapitreId and partieId using the provided indices
            const chapitreId = course.chapitres[chapterIndex]?._id;
            const partieId = course.chapitres[chapterIndex]?.parties[partieIndex]?._id;

            // Validate that the chapter and partie IDs exist
            if (!chapitreId || !partieId) {
                console.error('Error: Chapitre or Partie ID not found.');
                return;
            }

            // Send the form data to the backend using Axios
            await axiosInstance.post(`/courses/attachment/${id}/chapters/${chapitreId}/parties/${partieId}/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAttachmentModalOpen(false)


            fetchCourseData()
        } catch (error) {
            console.error('Error saving attachments:', error);
        }
    };


    const toggleChapterState = async (chapterId) => {


        const res = await toggleChaptire(id, chapterId)
        fetchCourseData();

    }
    const handleDeleteAttachment = async (partieIndex, attIndex, chapIndex) => {
        try {
            console.log(partieIndex, attIndex, chapIndex)
            // Get the IDs of the course, chapter, and party
            const chapitreId = courseData.chapitres[chapIndex]._id;

            const partieId = courseData.chapitres[chapIndex].parties[partieIndex]._id;
            const attachmentId = courseData.chapitres[chapIndex].parties[partieIndex].attachments[attIndex]._id;


            // Make an API call to delete the attachment
            await axiosInstance.delete(`/courses/attachment/${id}/chapters/${chapitreId}/parties/${partieId}/attachments/${attachmentId}`);

            // Update the state by removing the attachment
            const updatedParties = [...courseData.chapitres[chapIndex].parties];
            updatedParties[partieIndex].attachments.splice(attIndex, 1);

            // Update the state with the new array
            setCourseData(prevData => {
                const updatedChapters = [...prevData.chapitres];
                updatedChapters[chapIndex].parties = updatedParties;
                return { ...prevData, chapitres: updatedChapters };
            });

        } catch (error) {
            console.error("Error deleting attachment:", error);
        }
    };
    const openNewPartieModal = (y) => {
        setChaterID(y)
        setisAddNewPartieOpen(true)
    }

    const handleReorderVideos = async (chapterIndex, partieIndex, updatedVideos) => {
        console.log(updatedVideos)
        try {

            const response = await axiosInstance.put(`courses/updatedVideos/${id}/chapters/${chapterIndex}/parties/${partieIndex}/reorder-videos`, { videos: updatedVideos });


            if (!response.ok) {
                throw new Error('Failed to update video order');
            }

            const result = await response.json();
            console.log('Video order updated successfully:', result);


        } catch (error) {
            console.error('Error updating video order:', error);
        }
        fetchCourseData()
    };

    if (!courseData) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100vw"><Spinner size={"xl"} /></Box>;



    return (

        <Box height="100vh" ml={{ base: '0', md: isHovered ? '250px' : '60px' }} flexDirection="column">
            <Sidebar2 />

            <Box
                ml={{ base: '0', md: '250px' }}
                flex="1"
                p={6}
                bg="#F7FAFC"
                transition="margin-left 0.3s ease"
                minH={"100vh"}
                display="flex"
                justifyContent="space-between"
            >

                <Box spacing={8} alignItems="flex-start" w="40%">
                    <Heading size="lg" mb={4}>
                        Course: {courseData.name}
                    </Heading>
                    <Box display={"flex"}
                        justifyContent={"space-between"} alignItems={"center"}
                        mb={5}>
                        <Text fontSize="xl" color="#032D7F">Liste des chapitres</Text>

                        <Button leftIcon={<AddIcon />} size={"xs"} colorScheme="blue" onClick={onOpen}>
                            Add New Chapter
                        </Button>
                    </Box>
                    {courseData.chapitres.map((chapter, chapterIndex) => (
                        <Accordion key={chapterIndex} allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton

                                        onClick={() => toggleChapter(chapterIndex)}
                                        bg={accordionHeaderBg}
                                    >
                                        <Box flex="1" textAlign="left">
                                            <Badge>Chapitre {chapterIndex + 1}  :</Badge>  {chapter.nom}
                                        </Box>

                                        <AccordionIcon />
                                        <Button ml={5} mr={5} colorScheme="blue" size="xs" onClick={() => openNewPartieModal(courseData.chapitres[chapterIndex]._id)} >
                                            <FaPuzzlePiece />
                                        </Button>
                                        <Icon onClick={() => toggleChapterState(chapter.id)} color={chapter.isOpen ? "green" : "red"} as={chapter.isOpen ? FiUnlock : FiLock} w={6} h={6} mr={2} />

                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} bg={accordionBodyBg}>
                                    {chapter.parties.map((partie, partieIndex) => (
                                        <Accordion key={partieIndex} allowToggle>
                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton onClick={() => togglePartie(partieIndex)} bg={accordionHeaderBg}>
                                                        <Box flex="1" textAlign="left">
                                                            <Badge>Partie {partieIndex + 1}  :</Badge>   {partie.name}
                                                        </Box>

                                                        <AccordionIcon />
                                                        <Button ml={5} colorScheme="blue" size="xs" onClick={() => openVideoUploadModal(chapterIndex, partieIndex)} >
                                                            <FaVideo />
                                                        </Button>
                                                        <Button ml={5} colorScheme="teal" size="xs" onClick={() => { setAttachmentModalOpen(true); setSelectedChapterIndex(chapterIndex); setSelectedPartieIndex(partieIndex); }} >
                                                            <FaPaperclip />
                                                        </Button>
                                                        <Button ml={5} colorScheme="red" size="xs" onClick={() => handleDeletePartie(chapterIndex, partieIndex)} >
                                                            <FaTrash />
                                                        </Button>



                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4} bg={accordionBodyBg} position="relative">
                                                    <VideoCourseManger partie={partie} chapterIndex={chapterIndex} partieIndex={partieIndex} selectedVideo={selectedVideo} handleVideoSelect={handleVideoSelect} handleDeleteVideo={handleDeleteVideo} handleReorderVideos={handleReorderVideos} />


                                                    <VStack alignItems="flex-start">

                                                        {partie.attachments.length > 0 ? <Badge>Attachments:</Badge> : ""}
                                                        {partie.attachments.map((att, attIndex) => (
                                                            <Box key={attIndex} cursor="pointer" bg={'gray.200'} p={2} borderRadius="md" w="full">
                                                                <HStack justify="space-between">
                                                                    <Text as="a" href={urlvideo + att.url} target="_blank" rel="noopener noreferrer" color="blue.500">
                                                                        {att.nom}
                                                                    </Text>
                                                                    <Button
                                                                        colorScheme="red"
                                                                        size="xs"
                                                                        onClick={() => handleDeleteAttachment(partieIndex, attIndex, chapterIndex)}
                                                                    >
                                                                        <FaTrash />
                                                                    </Button>
                                                                </HStack>
                                                            </Box>
                                                        ))}
                                                    </VStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    ))}
                                </AccordionPanel>

                            </AccordionItem>
                        </Accordion>
                    ))}


                </Box>
                <Box w="50%" bg={"white"} p={"20px 5px"}>
                    <VStack spacing={4}  >



                        {selectedVideo && (
                            <>
                                <Text>{selectedVideo.nomvideo}</Text>
                                <VideoPlayer idvdocipher={selectedVideo.idvdocipher} />
                            </>

                        )}


                    </VStack>
                </Box>

            </Box>

            {/* Video Upload Modal */}
            <Modal isOpen={videoUploadModalOpen} onClose={handleCloseVideoUploadModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Video</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Video Name</FormLabel>
                            <Input
                                value={newVideo.name}
                                onChange={(e) => setNewVideo({ ...newVideo, name: e.target.value })}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={newVideo.description}
                                onChange={(e) => setNewVideo({ ...newVideo, description: e.target.value })}
                            />
                        </FormControl>
                        <Box {...getVideoRootProps()} p={4} border="1px dashed" borderColor="gray.300" cursor="pointer">
                            <input {...getVideoInputProps()} />
                            <Text>Drag and drop your video here, or click to select files</Text>
                        </Box>
                        {newVideo.thumbnail && (
                            <Box mt={4}>
                               
                                <Text mt={2}>Duration: {newVideo.duree}</Text>
                            </Box>
                        )}
                        {uploadProgress > 0 && (
                        <Box mt={4} mx={4}>
                            <CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`} />
                        </Box>
                    )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddVideo}>
                            Upload
                        </Button>
                        <Button variant="ghost" onClick={handleCloseVideoUploadModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Attachments Modal */}
            <Modal isOpen={attachmentModalOpen} onClose={() => setAttachmentModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Attachment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Input
                                type='text'
                                placeholder='Nom'
                                mb={5}
                                onChange={(e) => setAttchmentName(e.target.value)}
                            />
                        </Box>
                        <Text>Pièce jointe</Text>
                        <Box
                            {...getAttachmentRootProps()}
                            p={4}
                            border="1px dashed"
                            borderColor="gray.300"
                            cursor="pointer"
                        >
                            <input {...getAttachmentInputProps()} />
                            <Text>Drag and drop your file here, or click to select a file</Text>
                        </Box>
                        {attachments && (
                            <VStack mt={4} alignItems="flex-start">
                                <Box p={2} border="1px solid" borderColor="gray.300" borderRadius="md" w="full">
                                    <Text>{attachments.name}</Text>
                                </Box>
                            </VStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() =>
                                saveAttachmentsToPartie(selectedChapterIndex, selectedPartieIndex, [attachments])
                            }
                        >
                            Save
                        </Button>
                        <Button variant="ghost" onClick={() => setAttachmentModalOpen(false)}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            <ToastContainer />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Chapter</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder="Chapter name"
                            value={newChapterName}
                            onChange={(e) => setNewChapterName(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddChapter}>
                            Save
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <AddPartieForm isOpen={isAddNewPartieOpen} onClose={() => setisAddNewPartieOpen(false)} chapitreId={ChaterID} courseId={id} fetchCourseData={fetchCourseData} />

            <NewChapterToCourse fetchCourseData={fetchCourseData} courseId={id} isOpen={isOpen} onClose={onClose} />

        </Box>
    );
};

export default CourseDetailsContent;
