import axiosInstance from './axiosInstance'; // Import your axios instance

const userService = {
  // Fetch all users
  getUsers: async () => {
    try {
      const response = await axiosInstance.get('/users');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Add a new user
  addUser: async (userData) => {
    try {
      const response = await axiosInstance.post('/auth/register', userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Edit an existing user
  updateUser: async (userId, updatedData) => {
    try {
      const response = await axiosInstance.put(`/users/${userId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete a user
  deleteUser: async (userId) => {
    try {
      const response = await axiosInstance.delete(`/users/user/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default userService;
