import { Box, Text, Icon, Button, VStack, Image } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionIcon = motion(Icon);

const UnsupportedBrowser = () => (
  <Box dir="rtl">
    <MotionBox
      textAlign="center"
      p={10}
      bgGradient="linear(to-r, #003366, #032D7F)"
      color="white"
      borderRadius="lg"
      boxShadow="lg"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      minH={"100vh"}
    >
      <VStack spacing={6}>
        <MotionIcon
          as={WarningIcon}
          boxSize={12}
          color="yellow.400"
          animate={{ rotate: [0, 20, -20, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
        />
        <Text fontSize="3xl" fontWeight="bold">
          متصفح غير مدعوم
        </Text>
        <Image
          src="https://my.certara.net/assets/images/error-pages/error-browser-support.svg"
          alt="Unsupported Browser"
          borderWidth={2}
          borderColor="white"
          mb={4}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8 }}
        />
        <Text fontSize="lg" maxW="lg">
        للدخول الى منصة كوبرا يرجى تنزيل و تثبيت متصفح غوغل كروم الإصدار 127      </Text>
        <Button
         
        >
            <a href="https://www.google.com/chrome/">  تحميل متصفح غوغل كروم</a>
        
        </Button>
      </VStack>
    </MotionBox>
  </Box>
);

export default UnsupportedBrowser;
