import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Icon,
  useToast,
  Image,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import { WarningIcon, EmailIcon, LockIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';

const AlertMessage = ({ onAccept }) => {
  const fullText = `يمنع منعًا باتًا محاولة تسريب أو مشاركة ولو فيديو واحد من محتوى هذه المنصة التعليمية (Cobra Éducation).

بالرجوع إلى القانون عدد 36 المؤرخ في 24 فيفري 1994، تحديدًا الفصل 47 من الباب السادس، يتمتع محتوى هذه المنصة بحماية قانونية صارمة تبدأ من تاريخ الإصدار وتستمر لمدة 50 سنة.

أي محاولة لانتهاك هذا القانون، سواء من خلال تسريب أو نسخ أو نشر محتوى المنصة دون تصريح، ستعرضك لملاحقة قضائية صارمة. العقوبات تشمل الحكم بالسجن إلى جانب غرامات مالية كبيرة.

الرجاء احترام القوانين للحفاظ على الحقوق الفكرية والالتزام بما يضمن سلامة وأمان الجميع.`;
  
  const [typedText, setTypedText] = useState('');
  const typingSpeed = 100; // Speed of typing in milliseconds

  useEffect(() => {
    let index = 0;
    const typeText = () => {
      if (index < fullText.length) {
        setTypedText((prev) => prev + fullText.charAt(index));
        index++;
        setTimeout(typeText, typingSpeed);
      }
    };
    typeText();
  }, []);

  return (
    <Flex 
            bg="black"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      width={"100vw"}
      px={4}
    >
      <Box
        bg="black"
        p={8}
        width={"60vw"}
        borderRadius="md"
        boxShadow="lg"
height={"600px"}
        textAlign="center"
   

        color={"white"}
      >
        <Icon as={WarningIcon} w={12} h={12} color="red.500" mb={4} />
        <Heading size="lg" mb={4}>
          إعلام
        </Heading>
        <Text mb={6}      fontFamily="roboto" dir='rtl' fontSize={32} >
          {typedText}
        </Text>
        <Button
          colorScheme="red"
          onClick={onAccept}
          opacity={typedText !== fullText?0:1} // Disable button until text finishes typing
        >
        موافق
        </Button>
      </Box>
    </Flex>
  );
};

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const hasSeenAlert = localStorage.getItem('hasSeenAlert');
    if (hasSeenAlert) {
      setShowAlert(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('hasSeenAlert', 'true');
    setShowAlert(false);
  };

  const handleLogin = async () => {
    try {
      const response = await axiosInstance.post('/auth/login', { email, password });
      localStorage.setItem('userToken', response.data.token);
      toast({
        title: 'Connexion réussie.',
        description: `Bienvenue, ${email}!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      if (response.data.isAdmin) {
        navigate('/admin');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast({
        title: 'Erreur de connexion.',
        description: 'Email ou mot de passe incorrect.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (showAlert) {
    return <AlertMessage onAccept={handleAccept} />;
  }

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center" bg="gray.100">
      <Flex
        w={{ base: '90%', md: '80%', lg: '60%' }}
        bg="white"
        boxShadow="2xl"
        borderRadius="lg"
        overflow="hidden"
      >
        <Box flex="1" p={8}>
          <Image src="./assets/logo.png" alt="Logo" mb={5} mx="auto" h="150px" />

          <Heading textAlign="center" mb={6} fontSize="2xl">
            Bienvenue !
          </Heading>

          <VStack spacing={4} align="stretch">
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<EmailIcon color="gray.300" />} />
                <Input
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="password">
              <FormLabel>Mot de passe</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<LockIcon color="gray.300" />} />
                <Input
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            <Button
              bg="#032D7F"
              color="white"
              size="lg"
              width="full"
              _hover={{ bg: '#021E5A' }}
              onClick={handleLogin}
            >
              Connexion
            </Button>
          </VStack>
        </Box>

        <Box flex="1" display={{ base: 'none', md: 'block' }} bg="#032D7F">
          <Image
            src="./assets/login.jpg"
            alt="Image d'accompagnement"
            objectFit="cover"
            height="100%"
            width="100%"
            filter="brightness(0.75)"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
