import React, { useContext } from 'react';
import {
    Box,
    VStack,
    Icon,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
    useMediaQuery,
} from '@chakra-ui/react';
import {
    MdDashboard,
    MdEventNote,
    MdNotificationsNone,
    MdLibraryBooks,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../services/AuthContext';

const Sidebar = () => {
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const menuItems = [
        { icon: MdDashboard, path: '/', label: 'Tableau de bord' },
        { icon: MdEventNote, path: '/events', label: 'Événements' },
        { icon: MdNotificationsNone, path: '/notifications', label: 'Notifications' },
        { icon: MdLibraryBooks, path: '/courses', label: 'Courses' },
    ];

    return (
        <div >
            <div style={{width:"60px"}}></div>
            <Box
            position="fixed"
            top="0"
            left="0"
            w={isLargerThan768 ? "5%" : "full"}
            h="100vh"
            bg="#032D7F"
            color="white"
            display="flex"
            flexDirection={isLargerThan768 ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
            p={4}
        >
            <VStack spacing={8} mb={isLargerThan768 ? 0 : 4}>
                <Image src="https://api.cobra-education.com/uploads/images/logo.png" alt="Logo" size="lg" />
                {menuItems.map((item) => (
                    <Icon
                        as={item.icon}
                        w={6}
                        h={6}
                        key={item.path}
                        cursor="pointer"
                        onClick={() => navigate(item.path)}
                    />
                ))}
            </VStack>
            <Menu>
                <MenuButton as={Avatar} size="md" cursor="pointer" />
                <MenuList>
                    <MenuItem icon={<MdDashboard />} onClick={logout}>Déconnexion</MenuItem>
                </MenuList>
            </Menu>
        </Box>
        </div>
    );
};

export default Sidebar;
