import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, BellIcon, SettingsIcon } from '@chakra-ui/icons';
import Sidebar from '../shared/Sidebar';
import { SidebarProvider, useSidebar } from '../utils/SidebarContext';
import axiosInstance from '../services/axiosInstance';

const NotificationCard = ({ notification }) => (
  <Flex
    p={4}
    border="1px solid #E2E8F0"
    borderRadius="md"
    alignItems="center"
    bg="white"
    mb={4}
  >
    <Image src={notification.image} alt={notification.message} boxSize="50px" borderRadius="full" mr={4} />
    <Box>
      <Text fontWeight="bold">{notification.message}</Text>
      <Text fontSize="sm" color="gray.500">{notification.sender}</Text>
      <Text fontSize="xs" color="gray.400">{new Date(notification.createdAt).toLocaleTimeString()}</Text>
    </Box>
  </Flex>
);

const NotificationsContent = () => {
  const { isHovered } = useSidebar();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {



      axiosInstance.get('/notifications', {

      })
      .then(response => setNotifications(response.data))
      .catch(error => console.error('Error fetching notifications:', error));
    
  }, []);

  return (
    <Flex
      height="100vh"
      ml={{ base: '0', md: isHovered ? '250px' : '60px' }}  // Adjust for sidebar width
      flexDirection="column"
    >
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content Area */}
      <Box
      ml={10}
        flex="1"
        p={6}
        bg="#F7FAFC"
        transition="margin-left 0.3s ease"
        overflowY="auto"  // Allows scrolling if needed
      >
        {/* Header */}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading as="h1" size="lg" color="#032D7F" mb={{ base: 4, md: 0 }}>
            Notifications
          </Heading>
          <HStack spacing={4} flexDirection={{ base: 'column', md: 'row' }}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input type="text" placeholder="Recherche" />
            </InputGroup>
            <HStack spacing={2}>
              <IconButton icon={<BellIcon />} aria-label="Notifications" bg="white" />
              <IconButton icon={<SettingsIcon />} aria-label="Paramètres" bg="white" />
            </HStack>
          </HStack>
        </Flex>

        {/* Notification List */}
        <VStack spacing={4} align="stretch">
          {notifications.map((notification, index) => (
            <NotificationCard key={index} notification={notification} />
          ))}
        </VStack>
      </Box>
    </Flex>
  );
};

const Notifications = () => {
  return (
    <SidebarProvider>
      <NotificationsContent />
    </SidebarProvider>
  );
};

export default Notifications;
