import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  VStack,
  Image,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const ForgotPasswordPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const toast = useToast();
  const navigate = useNavigate(); // Hook pour la redirection

  const handleSubmit = () => {
    // Simuler l'envoi d'email
    setEmailSent(true);
    toast({
      title: 'Email envoyé.',
      description: "Veuillez vérifier votre boîte de réception pour le code de réinitialisation.",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    // Rediriger vers la page de réinitialisation du mot de passe
    setTimeout(() => {
      navigate('/reset-password');
    }, 2000); // Délai pour montrer le message avant la redirection
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <Flex height="100vh" alignItems="center" justifyContent="center" bg="gray.100">
        <Flex
          w={{ base: '90%', md: '80%', lg: '60%' }}
          bg="white"
          boxShadow="2xl"
          borderRadius="lg"
          overflow="hidden"
        >
          {/* Section Formulaire */}
          <Box flex="1" p={8}>
            {/* Logo */}
            <Image src="./assets/logo.png" alt="Logo" mb={5} mx="auto" h="150px" />

            <Heading textAlign="center" mb={6} fontSize="2xl">
              Réinitialiser le mot de passe
            </Heading>

            {/* Formulaire */}
            <VStack spacing={4} align="stretch">
              {/* Champ Email */}
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<EmailIcon color="gray.300" />} />
                  <Input
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              {emailSent ? (
                <Flex direction="column" align="center" mt={6}>
                  <CheckCircleIcon color="teal.500" boxSize={10} />
                  <Text mt={4} fontSize="lg">
                    Un code de réinitialisation a été envoyé à votre adresse e-mail.
                  </Text>
                  <Link as={RouterLink} to="/reset-password" color="#032D7F" fontWeight="bold">
                    Réinitialisez votre mot de passe ici
                  </Link>
                </Flex>
              ) : (
                <Button
                  bg="#032D7F"
                  color="white"
                  size="lg"
                  width="full"
                  _hover={{ bg: '#021E5A' }}
                  onClick={handleSubmit}
                >
                  Envoyer le code
                </Button>
              )}

              {/* Lien vers la connexion */}
              <Text textAlign="center" mt={6}>
                Retour à la <Link as={RouterLink} to="/login" color="#032D7F" fontWeight="bold">connexion</Link>.
              </Text>
            </VStack>
          </Box>

          {/* Section Image */}
          <Box flex="1" display={{ base: 'none', md: 'block' }} bg="#032D7F">
            <Image
              src="./assets/forgot.jpg"
              alt="Image d'accompagnement"
              objectFit="cover"
              height="100%"
              width="100%"
              filter="brightness(0.75)"
            />
          </Box>
        </Flex>
      </Flex>
    </motion.div>
  );
};

export default ForgotPasswordPage;
