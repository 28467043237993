import React, { useState } from 'react';
import {
  Calendar,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Box,
  Image,
  Text,
  HStack,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import { FaUserAlt } from 'react-icons/fa';
import { urlvideo } from '../services/urls';

const localizer = momentLocalizer(moment);

const EventCard = ({ event, onClick }) => (
  <Box
    border="1px solid #E2E8F0"
    borderRadius="md"
    overflow="hidden"
    p={4}
    bg="white"
    display="flex"
    alignItems="center"
    flexDirection={{ base: 'column', md: 'row' }}
    onClick={onClick}
    cursor="pointer"
  >
    <Image
      src={urlvideo + event.imageUrl}
      alt={event.title}
      boxSize="50px"
      borderRadius="full"
      mb={{ base: 2, md: 0 }}
      mr={{ base: 0, md: 4 }}
    />
    <Box textAlign={{ base: 'center', md: 'left' }}>
      <Text fontWeight="bold" color="gray.500">
        {event.title}
      </Text>
      <HStack
        spacing={2}
        mt={1}
        justifyContent={{ base: 'center', md: 'flex-start' }}
      >
        <Icon as={FaUserAlt} color="gray.500" />
        <Text fontSize="sm" color="gray.600">
          {event.professor}
        </Text>
      </HStack>
    </Box>
  </Box>
);

const CalendarComponent = ({ events }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    onOpen();
  };

  // Format events for the calendar
  const formattedEvents = events.map(event => ({
    ...event,
    start: moment(event.start).toDate(),
    end: moment(event.end).toDate(),
  }));

  return (
    <Box width="100%" height="100vh" p={4} bg="#f7fafc">
      <Calendar
        localizer={localizer}
        events={formattedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%', width: '100%' }}
        components={{
          event: (eventProps) => (
            <EventCard
              event={eventProps.event}
              onClick={() => handleEventClick(eventProps.event)}
            />
          ),
        }}
        popup
        views={['month', 'agenda']}
        step={60}
        showMultiDayTimes
        defaultDate={new Date()}
        min={new Date(2024, 7, 15, 8, 0)} // Start time 8 AM
        max={new Date(2024, 7, 15, 22, 0)} // End time 10 PM
      />

      {/* Modal for event details */}
      {selectedEvent && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedEvent.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                direction={{ base: 'column', md: 'row' }}
                alignItems="center"
              >
                <Image
                  src={urlvideo + selectedEvent.imageUrl}
                  alt={selectedEvent.title}
                  boxSize="100px"
                  borderRadius="full"
                  mr={{ base: 0, md: 4 }}
                  mb={{ base: 4, md: 0 }}
                />
                <Box>
                  <Text fontWeight="bold">
                    {selectedEvent.title}
                  </Text>
                  <HStack spacing={2} mt={1}>
                    <Icon as={FaUserAlt} color="gray.500" />
                    <Text fontSize="sm" color="gray.600">
                      {selectedEvent.professor}
                    </Text>
                  </HStack>
                  <Text mt={2} color="gray.600">
                    Start: {moment(selectedEvent.start).format('LLL')}
                  </Text>
                  <Text mt={2} color="gray.600">
                    End: {moment(selectedEvent.end).format('LLL')}
                  </Text>
                </Box>
              </Flex>
            </ModalBody>
            <Box display="flex" justifyContent="flex-end" p={4}>
              <Button onClick={onClose}>Close</Button>
            </Box>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default CalendarComponent;
