import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { SearchIcon, BellIcon, SettingsIcon } from '@chakra-ui/icons';
import Sidebar from '../shared/Sidebar';
import CalendarComponent from '../shared/Calendar';
import { SidebarProvider, useSidebar } from '../utils/SidebarContext';
import axiosInstance from '../services/axiosInstance'; // Adjust the path if needed

const EventsContent = () => {
  const { isHovered } = useSidebar();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get('/events');
        setEvents(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch events');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" color="#032D7F" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Sidebar />
      <Box
        flex="1"
        ml={{ base: '0', md: isHovered ? '250px' : '60px' }}
        p={6}
        bg="#F7FAFC"
        transition="margin-left 0.3s ease"
        minHeight="100vh"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading
            as="h1"
            size="lg"
            color="#032D7F"
            mb={{ base: 4, md: 0 }}
          >
            Événements
          </Heading>
          <HStack
            spacing={4}
            flexDirection={{ base: 'column', md: 'row' }}
            width={{ base: '100%', md: 'auto' }}
          >
            <InputGroup width="100%">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.300" />}
              />
              <Input type="text" placeholder="Recherche" />
            </InputGroup>
            <HStack spacing={2}>
              <IconButton
                icon={<BellIcon />}
                aria-label="Notifications"
                bg="white"
              />
              <IconButton
                icon={<SettingsIcon />}
                aria-label="Paramètres"
                bg="white"
              />
            </HStack>
          </HStack>
        </Flex>

       
        <CalendarComponent events={events} />
      </Box>
    </Flex>
  );
};

const Events = () => {
  return (
    <SidebarProvider>
      <EventsContent />
    </SidebarProvider>
  );
};

export default Events;
