import axiosInstance from './axiosInstance';
import { urlvideo } from './urls';

// Function to create a new course
export const createCourse = async (courseData) => {
    try {
        const response = await axiosInstance.post('/Courses/cour', courseData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error creating course:', error);
        throw error; // Rethrow the error for the caller to handle
    }
};

// Function to fetch all courses
export const fetchCourses = async () => {
    try {
        const response = await axiosInstance.get('/courses');
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
    }
};

// Function to fetch a specific course by ID
export const fetchCourseById = async (courseId) => {
    try {
        const response = await axiosInstance.get(`/courses/cours/${courseId}`);
      
        return response;
       
    } catch (error) {
        throw new Error(error.response ? error.response.data.message : error.message);
    }
};
export const updateCourse = async (courseId, data) => {
    try {
      const response = await axiosInstance.put(`${urlvideo}/courses/${courseId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating course:', error);
      throw error;
    }
  };
export const deleteVideo = async (id, chapterIndex, partieIndex, videoId) => {
    try {
        const response = await axiosInstance.delete(`/courses/${id}/chapters/${chapterIndex}/videos/${videoId}/${partieIndex}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting video:', error);
        throw new Error(error.response ? error.response.data.message : error.message);
    }
};
export const addChapter = async (courseId, chapter) => {
    try {
        const response = await axiosInstance.post(`/courses/chaptires/${courseId}/addChapter`, chapter);
        return response.data;
    } catch (error) {
        console.error('Error adding chapter:', error);
        throw error; // Rethrow error to be handled by caller
    }
};
export const toggleChaptire = async (courseId, chapterId) => {
    try {
        const response = await axiosInstance.put(`/courses/toggle/${courseId}/chapter/${chapterId}/toggle`);
        return response.data;
    } catch (error) {
        console.error('Error adding chapter:', error);
        throw error; // Rethrow error to be handled by caller
    }
};
