
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Calendar,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { urlvideo } from './../services/urls';
import EventCard from './EventCard';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import EditEventModal from './EditEventModal';
import axiosInstance from '../services/axiosInstance';

const localizer = momentLocalizer(moment);

const CalendarAdmin = ({ events, onDateClick,fetchEvents }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    onOpen();
  };

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setEditModalOpen(true);
  };

  const handleDelete = (event) => {
    setSelectedEvent(event);
    
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async (event) => {
   
    try {
 
     await axiosInstance.delete(`/events/${selectedEvent._id}`);
     setDeleteModalOpen(false)
     fetchEvents()
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleSaveEdit = async (updatedEvent) => {
    try {
      // Implement the actual update request here
      console.log('Update event:', updatedEvent);
       await axiosInstance.put(`/events/${updatedEvent._id}`, updatedEvent);
       fetchEvents()
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleDateClick = (start) => {
    const end = start; // Set end date to be the same as start date
    onDateClick({ start, end });
  };

  return (
    <Box width="100%" height="100vh" p={4} bg="#f7fafc">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%', width: '100%' }}
        components={{
          event: (eventProps) => (
            <EventCard
              event={eventProps.event}
              onClick={() => handleEventClick(eventProps.event)}
              onEdit={() => handleEdit(eventProps.event)}
              onDelete={() => handleDelete(eventProps.event)}
            />
          ),
        }}
        popup
        views={['month', 'agenda']}
        onSelectSlot={({ start }) => handleDateClick(start)}
        selectable
      />

      {/* Event Details Modal */}
      {selectedEvent && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedEvent.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={4}>Professeur: {selectedEvent.professor}</Text>
              <Text mb={4}>Début: {moment(selectedEvent.start).format('LLL')}</Text>
              <Text>Fin: {moment(selectedEvent.end).format('LLL')}</Text>
              {selectedEvent.imageUrl && (
                <img
                  src={urlvideo + selectedEvent.imageUrl}
                  alt={selectedEvent.title}
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              )}
              <Button onClick={onClose} mt={4}>
                Close
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      {selectedEvent && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
          event={selectedEvent}
        />
      )}

      {/* Edit Event Modal */}
      {selectedEvent && (
        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={() => setEditModalOpen(false)}
          event={selectedEvent}
          onSave={handleSaveEdit}
        />
      )}
    </Box>
  );
};

export default CalendarAdmin;
