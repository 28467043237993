import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  VStack,
  Image,
  Link,
  Input,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react';
import { LockIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PinInput from '../shared/PinInput'; // Import your custom PinInput component

const ResetPasswordPage = () => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetDone, setResetDone] = useState(false);
  const toast = useToast();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = () => {
    // Simuler la réinitialisation du mot de passe
    setResetDone(true);
    toast({
      title: 'Mot de passe réinitialisé.',
      description: "Votre mot de passe a été réinitialisé avec succès.",
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    
    // Redirect to login page after password reset
    setTimeout(() => {
      navigate('/login');
    }, 2000); // Adjust the delay as needed
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
    >
      <Flex height="100vh" alignItems="center" justifyContent="center" bg="gray.100">
        <Flex
          w={{ base: '90%', md: '80%', lg: '60%' }}
          bg="white"
          boxShadow="2xl"
          borderRadius="lg"
          overflow="hidden"
        >
          {/* Section Formulaire */}
          <Box flex="1" p={8}>
            {/* Logo */}
            <Image src="./assets/logo.png" alt="Logo" mb={5} mx="auto" h="150px" />

            <Heading textAlign="center" mb={6} fontSize="2xl">
              Réinitialiser votre mot de passe
            </Heading>

            {/* Formulaire */}
            <VStack spacing={4} align="stretch">
              {/* Champ Code de Réinitialisation */}
              <FormControl id="code" isRequired>
                
                <PinInput value={code} onChange={setCode} length={4} />
              </FormControl>

              {/* Champ Nouveau Mot de Passe */}
              <FormControl id="new-password" isRequired>
                <FormLabel>Nouveau mot de passe</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<LockIcon color="gray.300" />} />
                  <Input
                    type="password"
                    placeholder="Entrez votre nouveau mot de passe"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </InputGroup>
              </FormControl>

              {resetDone ? (
                <Flex direction="column" align="center" mt={6}>
                  <CheckCircleIcon color="teal.500" boxSize={10} />
                  <Text mt={4} fontSize="lg">
                    Votre mot de passe a été réinitialisé avec succès.
                  </Text>
                  <Link as={RouterLink} to="/login" color="#032D7F" fontWeight="bold">
                    Retour à la connexion
                  </Link>
                </Flex>
              ) : (
                <Button
                  bg="#032D7F"
                  color="white"
                  size="lg"
                  width="full"
                  _hover={{ bg: '#021E5A' }}
                  onClick={handleSubmit}
                >
                  Réinitialiser le mot de passe
                </Button>
              )}
            </VStack>
          </Box>

          {/* Section Image */}
          <Box flex="1" display={{ base: 'none', md: 'block' }} bg="#032D7F">
            <Image
              src="./assets/forgot.png"
              alt="Image d'accompagnement"
              objectFit="cover"
              height="100%"
              width="100%"
              filter="brightness(0.75)"
            />
          </Box>
        </Flex>
      </Flex>
    </motion.div>
  );
};

export default ResetPasswordPage;
