import React, { useState } from 'react';
import { 
  Button, FormControl, FormLabel, Input, VStack, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, 
  ModalBody, ModalCloseButton, useToast 
} from '@chakra-ui/react';
import axios from 'axios';
import axiosInstance from '../services/axiosInstance';

const AddPartieForm = ({ courseId, chapitreId,isOpen,onClose,fetchCourseData }) => {
  const [nomPartie, setNomPartie] = useState('');
  const [loading, setLoading] = useState(false);
 
  const toast = useToast();

  const handleAjouterPartie = async () => {
    try {
      setLoading(true);
      const nouvellePartie = {
        name: nomPartie,
        videos: [], 
        attachments: [] 
      };

      const response = await axiosInstance.post(`courses/courses/${courseId}/chapitres/${chapitreId}/parties`, nouvellePartie);
      

   

      toast({
        title: "Partie ajoutée.",
        description: "La nouvelle partie a été ajoutée avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setNomPartie(''); // Réinitialiser le formulaire
      fetchCourseData()
      onClose(); // Fermer la modale
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la partie:', error);
      toast({
        title: "Une erreur s'est produite.",
        description: "Impossible d'ajouter la partie.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter une nouvelle partie</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <FormControl isRequired>
                <FormLabel>Nom de la Partie</FormLabel>
                <Input
                  value={nomPartie}
                  onChange={(e) => setNomPartie(e.target.value)}
                  placeholder="Entrez le nom de la partie"
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAjouterPartie} isLoading={loading}>
              Ajouter la Partie
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddPartieForm;
