import React, { useState } from 'react';
import {
  Box,
  Image,
  Text,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaUserAlt, FaEllipsisV, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { urlvideo } from '../services/urls';

const EventCard = ({ event, onClick, onEdit, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
    onClick();
  };

  return (
    <Box
      border="1px solid #E2E8F0"
      borderRadius="md"
      
      p={4}
      bg="white"
      position="relative"
      cursor="pointer"
      onClick={handleCardClick}
      transition="all 0.3s ease"
      transform={isExpanded ? 'scale(1.05)' : 'scale(1)'}
    >
      {/* Three dots menu */}
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaTrashAlt/>}
          color={"#265985"}
          size="ml"
          variant="ghost"
          position="absolute"
          zIndex={9999}
          bottom="8px"
          right="8px"
          aria-label="Options"
          onClick={(e) => { e.stopPropagation(); onDelete(event);  }} // Prevent triggering the card click
        />
         <MenuButton
          as={IconButton}
          icon={<FaEdit />}
          size="ml"
          color={"#265985"}
          variant="ghost"
          position="absolute"
          zIndex={9999}
          bottom="8px"
          left="8px"
          aria-label="Options"
          onClick={(e) => { e.stopPropagation(); onEdit(event);}} // Prevent triggering the card click
        />
       
      </Menu>
<Box display={"flex"}>
    
<Image
        src={urlvideo+event.imageUrl}
        alt={event.title}
        boxSize={isExpanded ? "100px" : "50px"}
        borderRadius="full"
        mb={4}
      />
      <Box textAlign="center">
        <Text fontWeight="bold" color="gray.500" fontSize={isExpanded ? "lg" : "md"}>
          {event.title}
        </Text>
        <HStack spacing={2} mt={1} justifyContent="center">
          <Icon as={FaUserAlt} color="gray.500" />
          <Text fontSize="sm" color="gray.600">
            {event.professor}
          </Text>
        </HStack>
      </Box>
    </Box>
</Box>
  );
};

export default EventCard;
