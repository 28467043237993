import React from 'react';
import { VStack, HStack, Box, Badge, Button, Text } from '@chakra-ui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrash } from 'react-icons/fa';

const VideoCourseManger = ({ partie, chapterIndex, partieIndex, selectedVideo, handleVideoSelect, handleDeleteVideo, handleReorderVideos }) => {

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedVideos = Array.from(partie.videos);
        const [movedVideo] = updatedVideos.splice(result.source.index, 1);
        updatedVideos.splice(result.destination.index, 0, movedVideo);

        // Update the state with reordered videos
        console.log(chapterIndex, partieIndex)
        handleReorderVideos(chapterIndex, partieIndex, updatedVideos);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="videos">
                {(provided) => (
                    <VStack
                        alignItems="flex-start"
                        mt={5}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {partie.videos.length > 0 && <Badge>Videos:</Badge>}

                        {partie.videos.map((video, videoIndex) => (
                            <Draggable key={video.id} draggableId={video.id.toString()} index={videoIndex}>
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() => handleVideoSelect(video)}
                                        cursor="pointer"
                                        bg={selectedVideo?.id === video.id ? 'gray.500' : 'gray.200'}
                                        p={2}
                                        borderRadius="md"
                                        w="full"
                                    >
                                        <HStack justify="space-between">
                                            <Text>{video.nomvideo}</Text>
                                            <Button
                                                colorScheme="red"
                                                size="xs"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteVideo(chapterIndex, partieIndex, video.id);
                                                }}
                                            >
                                                <FaTrash />
                                            </Button>
                                        </HStack>
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </VStack>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default VideoCourseManger;
