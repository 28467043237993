import React, { useState, useRef } from 'react';
import {
  Box, HStack, Image, Text, Icon, Menu, MenuButton, MenuList, MenuItem, IconButton,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, Button, useToast
} from '@chakra-ui/react';
import { FaUserAlt, FaBook, FaVideo, FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { urlvideo } from './../services/urls';
import axios from 'axios';
import axiosInstance from '../services/axiosInstance';

const CourseCard2 = ({ course,refresh }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const onClose = () => setIsOpen(false);

  const handleDeleteCourse = async () => {
    try {
      await axiosInstance.delete(`Courses/${course._id}/delete`); // Replace with your delete API endpoint
      toast({
        title: "Course deleted.",
        description: "The course has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose(); 
      refresh()// Close the dialog after successful deletion
    } catch (error) {
        onClose(); 
      toast({
        title: "An error occurred.",
        description: "Unable to delete the course.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const navigateToCourseManagement = () => {
    navigate(`/admin/courses/${course._id}`);
  };

  const handleOpenDialog = () => setIsOpen(true);

  return (
    <Box
      border="1px solid #E2E8F0"
      borderRadius="md"
      overflow="hidden"
      bg="white"
      width="100%"
      height={"500px"}
      mb={4}
      cursor="pointer"
      position="relative"
    >
      <Image
        src={urlvideo + course.vignetteImage}
        alt={course.title}
        width="300px"
        height="300px"
      />
      <Box p={4}>
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          {course.name}
        </Text>
        <HStack spacing={2} mb={2}>
          <Icon as={FaUserAlt} color="gray.500" />
          <Text fontSize="sm" color="gray.600">
            {course.teacherName}
          </Text>
        </HStack>
        <HStack spacing={4} mb={4}>
          <HStack spacing={1}>
            <Icon as={FaBook} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {course.chapitres.length} chapitres
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Icon as={FaVideo} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {course.videos} vidéos
            </Text>
          </HStack>
        </HStack>
      </Box>

      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaEllipsisV />}
          variant="ghost"
          position="absolute"
          top="10px"
          right="10px"
        />
        <MenuList>
          <MenuItem onClick={navigateToCourseManagement}>Gérer le cours</MenuItem>
          <MenuItem onClick={handleOpenDialog} color="red.500">Supprimer le cours</MenuItem>
        </MenuList>
      </Menu>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le cours
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer ce cours ? Cette action est irréversible.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteCourse} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CourseCard2;
