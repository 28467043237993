import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  SimpleGrid,
  InputGroup,
  InputLeftElement,
  IconButton,
  Input,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon, BellIcon, SettingsIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../shared/Sidebar'; // Assuming Sidebar is already created
import { SidebarProvider, useSidebar } from '../utils/SidebarContext';
import { fetchCourses } from '../services/courseService';
import CourseCard from '../shared/CourseCard'; // Assuming CourseCard is in the same directory

const CoursesContent = () => {
  const { isHovered } = useSidebar();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCourses = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getCourses();
  }, []);

  if (loading) {
    return <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100vw"} height={"100vh"}>
<Spinner size="xl" color="#032D7F"/>

    </Box>;
  }

  if (error) {
    return <Text>Erreur: {error}</Text>;
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content Area */}
      <Box
        flex="1"
        ml={{ base: '0', md: isHovered ? '250px' : '60px' }}
        p={6}
        bg="#F7FAFC"
        transition="margin-left 0.3s ease"
        minHeight="100vh"
      >
        {/* Header */}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading as="h1" size="lg" color="#032D7F" mb={{ base: 4, md: 0 }}>
            Courses
          </Heading>
          <HStack spacing={4} flexDirection={{ base: 'column', md: 'row' }} width={{ base: '100%', md: 'auto' }}>
            <InputGroup width="100%">
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input type="text" placeholder="Search Courses" />
            </InputGroup>
            <HStack spacing={2}>
              <IconButton icon={<BellIcon />} aria-label="Notifications" bg="white" />
              <IconButton icon={<SettingsIcon />} aria-label="Settings" bg="white" />
            </HStack>
          </HStack>
        </Flex>

        {/* Courses List */}
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6}>
          {courses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

const Courses = () => {
  return (
    <SidebarProvider>
      <CoursesContent />
    </SidebarProvider>
  );
};

export default Courses;
