import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Text,
  useToast,
  useDisclosure,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import Sidebar2 from '../shared/Sidebar2';
import { createCourse, fetchCourses } from '../services/courseService';
import CourseCard2 from '../shared/CourseCard2';

const CoursesAdminContent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(0);
  const [courseName, setCourseName] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [numChapters, setNumChapters] = useState(1);
  const [chapterDetails, setChapterDetails] = useState([
    { name: '', numparties: 1, parties: [''] },
  ]);
  const [courses, setCourses] = useState([]);
  const toast = useToast();  // Initialize toast
  // State to track validation errors
  const [errors, setErrors] = useState({
    courseName: false,
    thumbnail: false,
    numChapters: false,
    chapterDetails: [
      {
        name: false,
        numparties: false,
        parties: [false],
      },
    ],
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => setThumbnail(acceptedFiles[0]),
    accept: 'image/*',
  });

  // Function to validate the current step
  const validateStep = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (step === 0) {
      // Validate courseName, thumbnail, numChapters
      newErrors.courseName = courseName.trim() === '';
      newErrors.thumbnail = !thumbnail;
      newErrors.numChapters = numChapters < 1;

      if (newErrors.courseName || newErrors.thumbnail || newErrors.numChapters) {
        valid = false;
      }
    } else if (step > 0 && step <= numChapters) {
      // Validate chapter name, numparties, and parts names
      const chapterIndex = step - 1;
      newErrors.chapterDetails = [...newErrors.chapterDetails];
      newErrors.chapterDetails[chapterIndex] = {
        ...newErrors.chapterDetails[chapterIndex],
        name: chapterDetails[chapterIndex].name.trim() === '',
        numparties: chapterDetails[chapterIndex].numparties < 1,
        parties: chapterDetails[chapterIndex].parties.map(
          (partName) => partName.trim() === ''
        ),
      };

      const chapterErrors = newErrors.chapterDetails[chapterIndex];
      if (
        chapterErrors.name ||
        chapterErrors.numparties ||
        chapterErrors.parties.some((error) => error)
      ) {
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChapterNameChange = (index, name) => {
    setChapterDetails((prev) => {
      const updated = [...prev];
      updated[index].name = name;
      return updated;
    });
    setErrors((prevErrors) => {
      const newChapterErrors = [...prevErrors.chapterDetails];
      newChapterErrors[index] = {
        ...newChapterErrors[index],
        name: name.trim() === '',
      };
      return { ...prevErrors, chapterDetails: newChapterErrors };
    });
  };

  const handleNumpartiesChange = (chapterIndex, numparties) => {
    setChapterDetails((prev) => {
      const updated = [...prev];
      const currentparties = updated[chapterIndex].parties;
      updated[chapterIndex].numparties = numparties;

      updated[chapterIndex].parties = currentparties.slice(0, numparties);
      if (numparties > currentparties.length) {
        updated[chapterIndex].parties = [
          ...currentparties,
          ...Array(numparties - currentparties.length).fill(''),
        ];
      }

      return updated;
    });

    setErrors((prevErrors) => {
      const newChapterErrors = [...prevErrors.chapterDetails];
      const currentErrors = newChapterErrors[chapterIndex];

      // Update numparties error
      currentErrors.numparties = numparties < 1;

      // Update parties errors
      const newPartiesErrors = currentErrors.parties.slice(0, numparties);
      while (newPartiesErrors.length < numparties) {
        newPartiesErrors.push(false);
      }
      currentErrors.parties = newPartiesErrors;

      newChapterErrors[chapterIndex] = currentErrors;
      return { ...prevErrors, chapterDetails: newChapterErrors };
    });
  };

  const handlePartNameChange = (chapterIndex, partIndex, name) => {
    setChapterDetails((prev) => {
      const updated = [...prev];
      updated[chapterIndex].parties[partIndex] = name;
      return updated;
    });
    setErrors((prevErrors) => {
      const newChapterErrors = [...prevErrors.chapterDetails];
      newChapterErrors[chapterIndex].parties[partIndex] = name.trim() === '';
      return { ...prevErrors, chapterDetails: newChapterErrors };
    });
  };

  const handleSave = async () => {
    // Before saving, ensure all steps are validated
    let allValid = true;
    const newErrors = { ...errors };

    // Validate all steps
    // Validate step 0
    newErrors.courseName = courseName.trim() === '';
    newErrors.thumbnail = !thumbnail;
    newErrors.numChapters = numChapters < 1;

    // Validate all chapters
    newErrors.chapterDetails = chapterDetails.map((chapter) => ({
      name: chapter.name.trim() === '',
      numparties: chapter.numparties < 1,
      parties: chapter.parties.map((part) => part.trim() === ''),
    }));

    // Check if any errors exist
    if (
      newErrors.courseName ||
      newErrors.thumbnail ||
      newErrors.numChapters ||
      newErrors.chapterDetails.some(
        (chapter) =>
          chapter.name ||
          chapter.numparties ||
          chapter.parties.some((partError) => partError)
      )
    ) {
      allValid = false;
    }

    setErrors(newErrors);

    if (!allValid) {
      // If there are validation errors, do not proceed
      return;
    }

    // Proceed with saving
    const chapters = chapterDetails.map((chapter) => ({
      nom: chapter.name,
      parties: chapter.parties.map((part) => ({ nom: part, videos: [] })),
    }));

    const formData = new FormData();
    formData.append('name', courseName);
    formData.append('vignetteImage', thumbnail);
    formData.append('chapitres', JSON.stringify(chapters));

    try {
      console.log(chapters);
      const response = await createCourse(formData);
      console.log('Course created:', response);
      fetchAndSetCourses();

      // Reset fields after saving
      setCourseName('');
      setThumbnail(null);
      setNumChapters(1);
      setChapterDetails([{ name: '', numparties: 1, parties: [''] }]);
      setErrors({
        courseName: false,
        thumbnail: false,
        numChapters: false,
        chapterDetails: [
          {
            name: false,
            numparties: false,
            parties: [false],
          },
        ],
      });
      setStep(0);
      toast({
        title: 'Cours créé avec succès.',
        description: "Le cours a été ajouté avec succès.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Error creating course:', error);
      toast({
        title: 'Erreur de création du cours.',
        description: "Une erreur s'est produite lors de la création du cours. Veuillez réessayer.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    onClose();
  };

  const fetchAndSetCourses = async () => {
    try {
      const courses = await fetchCourses();
      setCourses(courses || []);
    } catch (error) {
      console.error('Error fetching courses:', error);
      toast({
        title: 'Erreur de récupération des cours.',
        description: "Impossible de récupérer la liste des cours. Veuillez réessayer plus tard.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAndSetCourses();
  }, []);

  const renderStep = () => {
    if (step === 0) {
      return (
        <>
          <FormControl id="courseName" mb={4} isInvalid={errors.courseName}>
            <FormLabel>Titre du cours</FormLabel>
            <Input
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              placeholder="Entrez le titre du cours"
            />
            {errors.courseName && (
              <FormErrorMessage>Le titre du cours est requis.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="thumbnail" mb={4} isInvalid={errors.thumbnail}>
            <FormLabel>Image de vignette</FormLabel>
            <Box
              {...getRootProps()}
              border="2px dashed"
              borderColor={errors.thumbnail ? 'red.500' : 'gray.200'}
              p={4}
              textAlign="center"
              cursor="pointer"
            >
              <input {...getInputProps()} />
              {thumbnail ? (
                <Text>{thumbnail.name}</Text>
              ) : (
                <Text>Glissez-déposez ou cliquez pour télécharger</Text>
              )}
            </Box>
            {errors.thumbnail && (
              <FormErrorMessage>Une image de vignette est requise.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="numChapters" mb={4} isInvalid={errors.numChapters}>
            <FormLabel>Nombre de chapitres</FormLabel>
            <Input
              type="number"
              value={numChapters}
              min={1}
              max={10}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10) || 1;
                setNumChapters(value);
                setChapterDetails((prev) => {
                  let updated = [...prev];
                  if (value > updated.length) {
                    updated = [
                      ...updated,
                      ...Array(value - updated.length).fill({ name: '', numparties: 1, parties: [''] }),
                    ];
                  } else {
                    updated = updated.slice(0, value);
                  }

                  // Update errors.chapterDetails
                  setErrors((prevErrors) => {
                    let newChapterErrors = [...prevErrors.chapterDetails];
                    if (value > newChapterErrors.length) {
                      newChapterErrors = [
                        ...newChapterErrors,
                        ...Array(value - newChapterErrors.length).fill({
                          name: false,
                          numparties: false,
                          parties: [false],
                        }),
                      ];
                    } else {
                      newChapterErrors = newChapterErrors.slice(0, value);
                    }
                    return { ...prevErrors, chapterDetails: newChapterErrors };
                  });

                  return updated;
                });

                // Reset errors for numChapters
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  numChapters: value < 1,
                }));
              }}
              placeholder="Entrez le nombre de chapitres"
            />
            {errors.numChapters && (
              <FormErrorMessage>Le nombre de chapitres doit être au moins 1.</FormErrorMessage>
            )}
          </FormControl>
        </>
      );
    } else if (step > 0 && step <= numChapters) {
      const chapterIndex = step - 1;
      return (
        <Box key={chapterIndex} mb={4}>
          <FormControl
            id={`chapter-${chapterIndex}`}
            isInvalid={errors.chapterDetails[chapterIndex]?.name}
          >
            <FormLabel>Nom du chapitre {chapterIndex + 1}</FormLabel>
            <Input
              value={chapterDetails[chapterIndex]?.name || ''}
              onChange={(e) => handleChapterNameChange(chapterIndex, e.target.value)}
              placeholder={`Entrez le nom du chapitre ${chapterIndex + 1}`}
            />
            {errors.chapterDetails[chapterIndex]?.name && (
              <FormErrorMessage>Le nom du chapitre est requis.</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            id={`num-parties-${chapterIndex}`}
            mt={4}
            isInvalid={errors.chapterDetails[chapterIndex]?.numparties}
          >
            <FormLabel>Nombre de parties pour le chapitre {chapterIndex + 1}</FormLabel>
            <Input
              type="number"
              value={chapterDetails[chapterIndex]?.numparties || 1}
              min={1}
              max={10}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10) || 1;
                handleNumpartiesChange(chapterIndex, value);
              }}
              placeholder="Entrez le nombre de parties"
            />
            {errors.chapterDetails[chapterIndex]?.numparties && (
              <FormErrorMessage>
                Le nombre de parties doit être au moins 1.
              </FormErrorMessage>
            )}
          </FormControl>

          {chapterDetails[chapterIndex]?.parties?.map((partName, partIndex) => (
            <FormControl
              id={`part-${chapterIndex}-${partIndex}`}
              key={partIndex}
              mt={4}
              isInvalid={errors.chapterDetails[chapterIndex]?.parties[partIndex]}
            >
              <FormLabel>Nom de la partie {partIndex + 1}</FormLabel>
              <Input
                value={partName || ''}
                onChange={(e) =>
                  handlePartNameChange(chapterIndex, partIndex, e.target.value)
                }
                placeholder={`Entrez le nom de la partie ${partIndex + 1}`}
              />
              {errors.chapterDetails[chapterIndex]?.parties[partIndex] && (
                <FormErrorMessage>Le nom de la partie est requis.</FormErrorMessage>
              )}
            </FormControl>
          ))}
        </Box>
      );
    }
  };

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Sidebar2 />
      <Box
        flex="1"
        ml={{ base: '0', md: '250px' }}
        p={8}
        bg="#F5F7FA"
        transition="margin-left 0.3s ease"
        minHeight="100vh"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading as="h1" size="lg" color="#032D7F" mb={{ base: 4, md: 0 }}>
            Gestion des Cours
          </Heading>
          <HStack
            spacing={4}
            flexDirection={{ base: 'column', md: 'row' }}
            width={{ base: '100%', md: 'auto' }}
          >
            <Button
              bg="#032D7F"
              color="white"
              _hover={{ bg: '#021F5A' }}
              _active={{ bg: '#02123E' }}
              borderRadius="full"
              boxShadow="sm"
              p={3}
              onClick={onOpen}
            >
              Ajouter un nouveau cours
            </Button>
          </HStack>
        </Flex>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6}>
          {courses.map((course) => (
            <CourseCard2
              key={course._id}
              course={course}
              refresh={fetchAndSetCourses}
            />
          ))}
        </SimpleGrid>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ajouter un nouveau cours</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{renderStep()}</ModalBody>
            <ModalFooter>
              <Button
                bg="#032D7F"
                color="white"
                _hover={{ bg: '#021F5A' }}
                _active={{ bg: '#02123E' }}
                borderRadius="full"
                mr={3}
                onClick={() => {
                  if (validateStep()) {
                    if (step === numChapters) {
                      handleSave();
                    } else {
                      setStep(step + 1);
                    }
                  }
                }}
              >
                {step === numChapters ? 'Enregistrer' : 'Suivant'}
              </Button>
              {step > 0 && (
                <Button
                  bg="gray.300"
                  _hover={{ bg: 'gray.400' }}
                  borderRadius="full"
                  onClick={() => setStep(step - 1)}
                >
                  Retour
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default CoursesAdminContent;
