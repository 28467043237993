import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  VStack,
  Input,
  Badge,
  InputGroup,
  InputLeftElement,
  IconButton,
  Text,
  Icon,
  useBreakpointValue,
  SimpleGrid
} from '@chakra-ui/react';
import { SearchIcon, BellIcon, SettingsIcon, AtSignIcon, CalendarIcon, InfoIcon } from '@chakra-ui/icons';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import Sidebar from '../shared/Sidebar';
import { SidebarProvider, useSidebar } from '../utils/SidebarContext';
import axiosInstance from '../services/axiosInstance';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const DashboardContent = () => {
  const { isOpen } = useSidebar();
  const contentMarginLeft = useBreakpointValue({ base: '0px', md: isOpen ? '250px' : '100px' });

  const [coursesData, setCoursesData] = useState({});
  const [barData, setBarData] = useState({
    labels: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
    datasets: [
      {
        label: "Événements par jour",
        backgroundColor: '#3182CE',
        borderColor: '#3182CE',
        data: [],
      },
    ],
  });

  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#3182CE', '#63B3ED', '#BEE3E8'],
      },
    ],
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [barChartData, pieChartData, coursesData] = await Promise.all([
          axiosInstance.get('/dashboard/eventsPerDay'),
          axiosInstance.get('/dashboard/coursesByVideos'),
          axiosInstance.get('/statistics'),
        ]);

        setCoursesData(coursesData.data);

        setBarData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: Array.isArray(barChartData.data) ? barChartData.data : [],
            },
          ],
        }));

        const pieLabels = pieChartData.data.labels || [];
        const pieDataValues = pieChartData.data.data || [];

        setPieData({
          labels: pieLabels,
          datasets: [
            {
              data: pieDataValues,
              backgroundColor: ['#3182CE', '#63B3ED', '#BEE3E8'],
            },
          ],
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des données du tableau de bord:', error);
      }
    };

    fetchDashboardData();
  }, []);

  const barOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Jour de la semaine',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Nombre d\'événements',
        },
      },
    },
  };

  const pieOptions = {
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const label = tooltipItem.label || '';
            const value = tooltipItem.raw || '';
            return `${label}: ${value} vidéos`;
          },
        },
      },
    },
  };

  return (
    <Flex h="100vh" overflowX="hidden" flexDirection="column">
      <Sidebar />

      <Box
        flex="1"
        ml={contentMarginLeft}
        p={6}
        bg="#F7FAFC"
        transition="margin-left 0.3s ease"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Heading as="h1" size="lg" color="#032D7F" mb={{ base: 4, md: 0 }}>
            Tableau de bord
          </Heading>
          <HStack spacing={4} flexDirection={{ base: 'column', md: 'row' }}>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input type="text" placeholder="Recherche" />
            </InputGroup>
            <HStack spacing={2}>
              <IconButton icon={<BellIcon />} aria-label="Notifications" bg="white" />
              <IconButton icon={<SettingsIcon />} aria-label="Paramètres" bg="white" />
            </HStack>
          </HStack>
        </Flex>

        <VStack spacing={4} align="stretch">
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
            {/* Full-width Overview Cards */}
            <Box bg="white" p={6} borderRadius="md" shadow="md" textAlign="center">
              <Icon as={AtSignIcon} color="blue.500" boxSize={8} mb={4} />
              <Text fontSize="lg" fontWeight="bold">Nombre de Cours</Text>
              <Text color="blue.500" fontSize="2xl" mt={2}>{coursesData.totalCourses || 0}</Text>
            </Box>

            <Box bg="white" p={6} borderRadius="md" shadow="md" textAlign="center">
              <Icon as={InfoIcon} color="green.500" boxSize={8} mb={4} />
              <Text fontSize="lg" fontWeight="bold">Nombre de Vidéos</Text>
              <Text color="green.500" fontSize="2xl" mt={2}>{coursesData.totalVideos || 0}</Text>
            </Box>

            <Box bg="white" p={6} borderRadius="md" shadow="md" textAlign="center">
              <Icon as={CalendarIcon} color="purple.500" boxSize={8} mb={4} />
              <Text fontSize="lg" fontWeight="bold">Nombre d'Événements</Text>
              <Text color="purple.500" fontSize="2xl" mt={2}>{coursesData.totalEvents || 0}</Text>
            </Box>
          </SimpleGrid>

          <Flex gap={4} flexWrap="wrap" justifyContent="space-between">
            <Box bg="white" p={6} borderRadius="md" shadow="md" flex="1" minW={{ base: '100%', md: '48%' }}>
              <Heading as="h3" size="md" color="#032D7F" mb={4}>
                Événements par jour
              </Heading>
              <Bar data={barData} options={barOptions} />
            </Box>
            <Box bg="white" p={6} borderRadius="md" shadow="md" flex="1" minW={{ base: '100%', md: '48%' }}>
              <Heading as="h3" size="md" color="#032D7F" mb={4}>
                Cours par vidéos
              </Heading>
              <Pie data={pieData} options={pieOptions} />
            </Box>
          </Flex>
        </VStack>
      </Box>
    </Flex>
  );
};

const Dashboard = () => (
  <SidebarProvider>
    <DashboardContent />
  </SidebarProvider>
);

export default Dashboard;
