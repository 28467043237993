import React, { useContext, useState } from 'react';
import { 
  Box, 
  Flex, 
  VStack, 
  Text, 
  Icon, 
  Link as ChakraLink, 
  Image, 
  useDisclosure, 
  Drawer, 
  DrawerOverlay, 
  DrawerContent, 
  DrawerCloseButton, 
  DrawerHeader, 
  DrawerBody, 
  IconButton 
} from '@chakra-ui/react';
import { FaUsers, FaBook, FaBell, FaCalendarAlt, FaTachometerAlt, FaBars,FaSignOutAlt  } from 'react-icons/fa'; // Added FaBars for mobile menu
import { Link } from 'react-router-dom';
import AuthContext from '../services/AuthContext';

const Sidebar2 = () => {
  const { logout } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useState(window.innerWidth < 768);

  return (
    <Box>
      {/* Mobile Menu Icon */}
      {isMobile && (
        <IconButton
          icon={<FaBars />}
          onClick={onOpen}
          size="md"
          variant="outline"
          aria-label="Open Menu"
          m={4}
        />
      )}

      {/* Desktop Sidebar */}
      <Box
        as="nav"
        pos="fixed"
        left={0}
        top={0}
        h="100vh"
        w={{ base: 'full', md: '250px' }}
        bg="white"
        boxShadow="md"
        zIndex={1}
        display={{ base: 'none', md: 'block' }}
      >
        {/* Logo Section */}
        <Flex alignItems="center" justifyContent="center" p={4} borderBottom="1px solid" borderColor="gray.200">
          <Image src="https://i.imgur.com/YzqXoog.png" alt="Logo" w="120px" />
        </Flex>

        {/* Navigation Links */}
        <VStack spacing={4} mt={8} align="stretch">
          <NavItem icon={FaTachometerAlt} label="Tableau de bord" to="/admin" />
          <NavItem icon={FaUsers} label="Users" to="/admin/users" />
          <NavItem icon={FaBook} label="Cours" to="/admin/cours" />
         
          <NavItem icon={FaCalendarAlt} label="Events" to="/admin/events" />
          <NavItem icon={FaSignOutAlt  }   label="Log out" to="/" onClick={()=>{logout()}} />
        </VStack>
      </Box>

      {/* Mobile Drawer Sidebar */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image src="../assets/logo.png" alt="Logo" w="120px" />
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <NavItem icon={FaTachometerAlt} label="Tableau de bord" to="/admin" onClick={onClose} />
              <NavItem icon={FaUsers} label="Users" to="/admin/users" onClick={onClose} />
              <NavItem icon={FaBook} label="Cours" to="/admin/cours" onClick={onClose} />
              <NavItem icon={FaCalendarAlt} label="Events" to="/admin/events" onClick={onClose} />
              <NavItem icon={FaSignOutAlt } label="Log out" to="/" onClick={()=>{onClose();logout()}} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

// NavItem Component
const NavItem = ({ icon, label, to, onClick }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }} onClick={onClick}>
      <Flex align="center" p={4} pl={6} role="group" cursor="pointer" _hover={{ bg: 'gray.100' }}>
        <Icon as={icon} color="#032D7F" boxSize="20px" />
        <Text ml={4} fontSize="md" fontWeight="bold" color="#032D7F">
          {label}
        </Text>
      </Flex>
    </Link>
  );
};

export default Sidebar2;
