import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const EditEventModal = ({ isOpen, onClose, event, onSave }) => {
  const [eventName, setEventName] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Use useEffect to set initial state values when `event` changes
  useEffect(() => {
    if (event) {
      setEventName(event.title || '');
      setStartDate(new Date(event.start) || new Date());
      setEndDate(new Date(event.end) || new Date());
    }
  }, [event]);

  const handleSave = () => {
    if (!event) return;

    onSave({
      ...event,
      title: eventName,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Event</ModalHeader>
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Event Name</FormLabel>
            <Input
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="datetime-local"
              value={startDate.toISOString().slice(0, 16)}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>End Date</FormLabel>
            <Input
              type="datetime-local"
              value={endDate.toISOString().slice(0, 16)}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditEventModal;
