import React from 'react';
import { Box, Input } from '@chakra-ui/react';

const PinInput = ({ value, onChange, length = 4 }) => {
  const handleChange = (e, index) => {
    const newValue = e.target.value;
    const newPin = value.split('');
    newPin[index] = newValue;
    onChange(newPin.join(''));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {Array.from({ length }).map((_, index) => (
        <Input
          key={index}
          type="text"
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          maxLength="1"
          textAlign="center"
          width="50px"
          height="50px"
          margin="0 4px"
          borderRadius="md"
          borderColor="gray.300"
          fontSize="lg"
        />
      ))}
    </Box>
  );
};

export default PinInput;
