import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get('/auth/verify-token');
        setUser(response.data.user);
      } catch (error) {
        setUser(null);
        localStorage.removeItem('userToken');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
    
    const interval = setInterval(async () => {
      try {
        await axiosInstance.get('/auth/check-token');
      } catch (error) {
        setUser(null);
        localStorage.removeItem('userToken');
        navigate('/login');
        clearInterval(interval);
      }
    }, 60000); // Vérifier toutes les minutes

    return () => clearInterval(interval);
  }, [navigate]);

  const logout = async () => {
    try {

      localStorage.removeItem('userToken');
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, loading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
