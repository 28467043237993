import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import "./App.css";
import AuthContext from './components/services/AuthContext';
import { Box, Button, Text, VStack, Image } from '@chakra-ui/react';
import LoginPage from './components/pages/LoginPage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import Dashboard from './components/pages/Dashboard';
import Events from './components/pages/Events';
import Notifications from './components/pages/Notifications';
import Courses from './components/pages/Courses';
import CourseDetails from './components/pages/CourseDetails';
import DashboardAdmin from './components/pages/AdminDashboard';
import UserAdminManagement from './components/pages/UserAdminManagement';
import CourseAdminManagement from './components/pages/CourseAdminManagement';
import EditierAdminCourse from './components/pages/EditierAdminCourse';
import AdminEvents from './components/pages/AdminEvent';
import { AuthProvider } from './components/services/AuthContext';
import UnsupportedBrowser from './components/shared/UnsupportedBrowser';

const PrivateRoute = ({ children, isAdminRoute }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) return null; // or a loading spinner

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isAdminRoute && !user.IsAdmin) {
    return <Navigate to="/" />;
  }

  if (!isAdminRoute && user.IsAdmin) {
    return <Navigate to="/admin" />;
  }

  return children;
};
const getBrowserInfo = () => {
  const userAgent = window.navigator.userAgent;
  let browserName = "unknown";
  let fullVersion = "0.0";

  if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Edg") === -1) {
    browserName = "Chrome";
    fullVersion = userAgent.substring(userAgent.indexOf("Chrome") + 7).split(" ")[0];
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
    fullVersion = userAgent.substring(userAgent.indexOf("Firefox") + 8).split(" ")[0];
  } else if (userAgent.indexOf("Edg") > -1) {
    browserName = "Edge";
    fullVersion = userAgent.substring(userAgent.indexOf("Edg") + 4).split(" ")[0];
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edg") === -1) {
    browserName = "Safari";
    fullVersion = userAgent.substring(userAgent.indexOf("Version") + 8).split(" ")[0];
  }

  const majorVersion = parseInt(fullVersion.split(".")[0]);
  return { browserName, majorVersion };
};

const isSupportedBrowser = () => {
  const { browserName, majorVersion } = getBrowserInfo();

  // Define supported versions
  const supportedBrowsers = {
    Chrome: 126,   // Replace 100 with the minimum supported version for Chrome

  };

  return supportedBrowsers[browserName] && majorVersion >= supportedBrowsers[browserName];
};



const App = () => {
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    if (!isSupportedBrowser()) {
      setIsSupported(false);
    }
  }, []);

  return (
    <div id="app-container">
      <AuthProvider>
        <AnimatePresence>
          <Box>
            {isSupported ? (
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />

                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/events"
                  element={
                    <PrivateRoute>
                      <Events />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/notifications"
                  element={
                    <PrivateRoute>
                      <Notifications />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/courses"
                  element={
                    <PrivateRoute>
                      <Courses />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/courses/:id"
                  element={
                    <PrivateRoute>
                      <CourseDetails />
                    </PrivateRoute>
                  }
                />

                {/* Routes Admin */}
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute isAdminRoute>
                      <DashboardAdmin />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/users"
                  element={
                    <PrivateRoute isAdminRoute>
                      <UserAdminManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/cours"
                  element={
                    <PrivateRoute isAdminRoute>
                      <CourseAdminManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/courses/:id"
                  element={
                    <PrivateRoute isAdminRoute>
                      <EditierAdminCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/events"
                  element={
                    <PrivateRoute isAdminRoute>
                      <AdminEvents />
                    </PrivateRoute>
                  }
                />
              </Routes>
            ) : (
              <UnsupportedBrowser />
            )}
          </Box>
        </AnimatePresence>
      </AuthProvider>
    </div>
  );
};

function MobileMessage() {
  return (
    <Box
      display={{ base: 'flex', md: 'none' }}
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      p={6}
      backgroundImage="url('../assets/login.jpg')" // Add a background image
      backgroundSize="cover"
      backgroundPosition="center"
      color="white"
      bgGradient="linear(to-r, teal.500, blue.500)"
    >
      <VStack spacing={6}>
        <Image
          src="https://i.imgur.com/y0sAGgm.png" // App icon or logo
          alt="App Icon"
          boxSize="100px"
          mb={4}
        />
        <Text fontSize="2xl" fontWeight="bold" letterSpacing="wider">
          Get the Full Experience
        </Text>
        <Text fontSize="lg" maxW="80%">
          To access our platform, please download our mobile application.
        </Text>
        <Button
          colorScheme="teal"
          size="lg"
          px={8}
          py={6}
          borderRadius="full"
          boxShadow="lg"
          onClick={() => window.location.href = 'https://api.cobra-education.com/uploads/apps/Elcobra.apk'}
        >
          Download the App
        </Button>
        <Text fontSize="sm" opacity={0.8}>
          Available on Android
        </Text>
      </VStack>
    </Box>
  );
}

export default function MainApp() {
  return (
    <>
      <App />
      <MobileMessage />
    </>
  );
}
